import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import SimpleModalVideo from '../components/utilities/SimpleModalVideo';

import './spla.scss';

class SplaForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                window.open(
                    'https://mm-stats.com/xibfc',
                    '_blank' // <- This is what makes it open in a new tab.
                );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"namehero"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"emailhero"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companyhero"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephonehero"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">SUBMIT</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/microsoft-spla/" />
                    <input type="hidden" name="auth" value="877389d9-b572-4727-9bc2-9e4a384c0613" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

class Video extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };
    render() {
        return (
        <div className="col-12 col-md-4 order-md-1">
            <img src="https://www.meritmile.com/uploads/2020/02/spla-03@2x.png" alt="Microsoft SPLA" className="img-responsive" onClick={() => this.openModal()} />
            <SimpleModalVideo videoSRC='fzMcxstdh5A' innerRef={this.modalRef}/>
        </div>
        );
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} spla-template`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                        <h1>Microsoft SPLA<span className="bar"></span></h1>
                        </div>
                        <div className="col-md-4 hero-bg">
                        <div className="inside-hero text-center">
                            <h2>Discover the Top 50 Ways to Succeed with Microsoft SPLA.</h2>
                            <div className="form-wrapper">
                                <SplaForm />
                                <div className="thankyou">
                                <h4>Thank You</h4>
                                <p>If the report does not immediately open please check your pop-up blocker. A copy will also be sent to your email.</p>
                                <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <img src="https://www.meritmile.com/uploads/2020/02/spla-01@2x.png" alt="Microsoft SPLA" className="img-responsive" />
                        </div>
                        <div className="col-12 col-md-7">
                            <p>Since 2007, Merit Mile has launched hundreds of uniquely crafted Microsoft Services Provider Licensing Agreement (Microsoft SPLA) and cloud services marketing programs for global ISVs, Resellers, Distributors and Hosters / Service Providers. Our deep understanding of the licensing rules and user rights of SPLA enables us to create compelling and persuasive marketing campaigns to help attract new channel partners to your SPLA resell or new customers to your hosting services business.</p>
                            <p>Submit the form at the top of the page to download our top 50 ways for prospecting, generating demand, and onboarding new Microsoft SPLA partners and customers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2>SPLA Resellers</h2>
                            <p>As a Microsoft SPLA Reseller, do you have a reliable hoster / service provider recruitment strategy? Are you maximizing performance incentives and executing against a business plan?</p>
                            <h3>We can help you –</h3>
                            <ul>
                                <li>Clearly define your SPLA reseller value message and business differentiators.</li>
                                <li>Custom design and develop partner and customer readiness portals, tools, and resources.</li>
                                <li>Generate more qualified leads with partner recruitment programs using tested and proven practices.</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src="https://www.meritmile.com/uploads/2020/02/spla-02@2x.png" alt="Microsoft SPLA" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="videowrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 order-md-12 col-lg-6">
                            <h2>SPLA Service Providers</h2>
                            <p>As a Microsoft SPLA hoster / service provider, do you have a reliable demand generation strategy? Does your brand have cloud awareness in your marketplace? Is your team equipped to sell SPLA? Are you aware of all the licensing requirements? Is your business growing and are you profitable?</p>
                            <h3>We can help you –</h3>
                            <ul>
                                <li>Strategically define your service value proposition and clearly distinguish your hosted offerings.</li>
                                <li>Drive customer demand with persuasive lead generation and content marketing strategies.</li>
                                <li>Shorten the sales cycle with tools and sales techniques that drive the customer conversation.</li>
                            </ul>
                        </div>
                        <Video />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <p className="">Submit the form and discover compelling techniques for prospecting, generating demand, and onboarding new SPLA customers.</p>
                            <p className="disclaimer">Submitting the form gives us consent to send you emails which, of course, you are welcome to opt-out of at any time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="maxbtnwidth">Learn more about our technology marketing expertise at</p>
                            <p><a href="/technology-marketing/" className="btn btn-secondary">www.meritmile.com/technology-marketing</a></p>
                            <p className="maxbtnwidth">and our experience in marketing for Microsoft CSP Indirect Providers, Direct Providers, and Indirect Resellers at</p>
                            <p><a href="/microsoft-csp/" className="btn btn-secondary">www.meritmile.com/microsoft-csp</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutLPOne>
)